// tslint:disable:variable-name
export const aws_cognito_region = process.env.REACT_APP_AWS_COGNITO_REGION;
export const aws_user_pools_id = process.env.REACT_APP_USER_POOL_ID;
export const aws_user_pools_web_client_id = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID;
export const account_base_url = process.env.REACT_APP_ACCOUNT_BASE_URL;
export const idle_timeout = process.env.REACT_APP_IDLE_TIMEOUT;

export const metabase_dashboard_rpt_id = process.env.REACT_APP_METABASE_DASHBOARD_RPT_ID;
export const tenant_base_url = process.env.REACT_APP_TSP_BASE_URL || '';
export const PBA_FEATURE_FLAG = process.env.REACT_APP_PBA_FEATURE_FLAG === 'true';

export const features_turn_off = (process.env.REACT_APP_FEATURES_TURN_OFF || '').split(',');
export enum Features {
  dashboard = 'dashboard',
  pat_links = 'pat_links',
  transactions = 'transactions',
  customers = 'customers',
  merchants = 'merchants',
  user_management = 'user_management',
  audit_trail = 'audit_trail',
  audit_trail_user = 'audit_trail_user',
  integration_widget = 'integration_widget',
  reports = 'reports',
  reconciliation_report = 'reconciliation_report',
  customer_report = 'customer_report',
  transaction_report = 'transaction_report',
  settings = 'settings',
}
