import { useSettings } from '@fabric/ui';
import React, { useState } from 'react';
import { OnlineNavigations, PbaNavigations } from '../settings';
import { Select } from 'antd';

enum NavigationSelections {
  Online = 'Online',
  PayByAccount = 'Pay By Account',
}

const getNavigationComponents = (value: string) => {
  switch (value) {
    case NavigationSelections.Online:
      return OnlineNavigations;
    case NavigationSelections.PayByAccount:
      return PbaNavigations;
    default:
      return [];
  }
};
const NavigationSelection: React.FunctionComponent = () => {
  const { settings, overrideSettings } = useSettings();
  const [navigation, setNavigation] = useState<string>(NavigationSelections.Online);

  const onNavigationChanged = (value: string) => {
    if (overrideSettings) {
      overrideSettings({
        ...settings,
        navigations: getNavigationComponents(value.trim()),
      });
    }
  };

  return (
    <React.Fragment>
      <Select
        style={{ width: '100%' }}
        onChange={onNavigationChanged}
        onSearch={(v) => setNavigation(v)}
        showSearch={true}
        searchValue={navigation}
        filterOption={false}
      >
        {Object.values(NavigationSelections).map((navigation, id) => (
          <Select.Option key={id} value={navigation}>
            {navigation}
          </Select.Option>
        ))}
      </Select>
    </React.Fragment>
  );
};

export default NavigationSelection;
