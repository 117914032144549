import { PbaReportsPath, RouteConfigWrapper } from '@fabric/ui/src/commons';
import DelegateAccessIframe from '../DelegateAccess/iframe';

const pbaReportsRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: PbaReportsPath,
      exact: true,
      component: () => <DelegateAccessIframe title="Reports" component="pbaReports" />,
    },
  },
];

export default pbaReportsRoutes;
