import { PbaTransactionPath, RouteConfigWrapper } from '@fabric/ui/src/commons';
import PbaTransactionDelegateAccess from './PbaTransactionDelegateAccess';

const pbaTransRoutes: RouteConfigWrapper[] = [
  {
    route: {
      path: PbaTransactionPath,
      exact: true,
      component: () => <PbaTransactionDelegateAccess />,
    },
  },
];

export default pbaTransRoutes;
